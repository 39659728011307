import { IFilter, IHashTable } from "./interface.service";
import { CommonService } from "./common-functions.service";
import { isNullOrUndefined } from "util";
import { CPOSEnum, InputType } from "./enum.service";
import { TemplateRef } from "@angular/core";

export class CommonFilter implements IFilter {
    constructor(_currentPage: CPOSEnum = CPOSEnum.None, _pageSize = 10) {
        this.pageNumber = 1;
        this.pageSize = _pageSize;
        this.isExport = false;
        this.currentPage = _currentPage;
        // if (_companyId === 0) {
        //     this.companyId = CommonService.defaultComanyId;
        // } else {
        //     this.companyId = _companyId;
        // }
        // if (isNullOrUndefined(this.yearKey) && this.yearKey === 0) {
        //     this.yearKey = CommonService.defaultYearKey;
        // }
        // this.idOnly = false;
        this.filterArray = new Array<IHashTable>();
    }
    currentPage: CPOSEnum;
    pageNumber: number;
    pageSize: number;
    isExport: boolean;
    fromDate: string;
    toDate: string;
    orderBy: string;
    searchField: string;
    searchValue: string;
    filterArray: Array<IHashTable>;
    example: string;
}

export class ApiResponse {
    status: boolean;
    message: string;
    data: any
}

export class StoreAdvanceSearch extends CommonFilter {
    StoreCode = '';
    Store_Name = '';
    Address = '';
    District = '';
    // Git Id: 33  Date: 05-12-2024  Developer: Pankaj Motwani
    Store = '';
    State = '';
    IsAllFilteredStoreSelect = false;
}

export class Documents {
    constructor(_documentName: string, _documentPath: string) {
        if (_documentPath && _documentName) {
            this.documentName = _documentName;
            this.documentPath = _documentPath;
        }
    }
    poNo = 0;
    documentPath = '';
    documentName = '';
    documentType = '';
    uploadDate = new Date();
    uploadBy = '';
    documentSize = '';
}


export class TableFooter {
    constructor(private _columnName, private value) {
        this.columnName = _columnName;
        this.fieldvalue = value;
    }
    columnName: string;
    fieldvalue: string;
}

export class TableObject {
    constructor(private _cellDef: Array<cellDef>, private _actionButton: number, _isServerSidePaging: boolean, _defaultSearchField: string, _deaultFilter: CommonFilter = null, _pageEnum: number, _showHistory: boolean = false) {
        this.cellDefArray = _cellDef;
        this.actionButton = _actionButton;
        this.isServerSidePaging = (_isServerSidePaging) ? _isServerSidePaging : false;
        this.defaultSearchField = _defaultSearchField;
        this.deaultFilter = _deaultFilter;
        this.pageEnum = _pageEnum;
        this.showHistory = _showHistory;
    }
    cellDefArray: Array<cellDef>
    actionButton: number;
    isServerSidePaging: boolean;
    defaultSearchField: string;
    deaultFilter: CommonFilter;
    pageEnum: any;
    showHistory: boolean;
}

export class cellDef {
    constructor(_displayName, _headerName, _showInFieldDDl, _fieldType, _footerField, _tooltipField, _cellCSS = '', _fieldPrefix = '', _subReportId = 0,
        _isDisplay = true, _autoSearchKey = '', _defDateDurationInDays = 0, _pageId= 0) {
        this.displayName = _displayName;
        this.headerName = _headerName;
        this.showInFieldDDl = _showInFieldDDl;
        this.fieldType = _fieldType;
        this.footerField = _footerField;
        this.tooltipField = _tooltipField;
        this.cellCSS = _cellCSS;
        this.fieldPrefix = _fieldPrefix;
        this.subReportId = _subReportId;
        this.isDisplay = _isDisplay;
        this.autoSearchKey = _autoSearchKey;
        this.defDateDurationInDays = _defDateDurationInDays;
        //~~~~~~~~~~~~ Tushar 16-10-2023 GID 477
        this.tableFieldName = _displayName;
        this.pageId = _pageId;
        //~~~~~~~~~~~~ GID 536
    }
    displayName: string;
    headerName: string;
    showInFieldDDl: boolean;
    fieldType: InputType;
    footerField: string;
    tooltipField: TemplateRef<any>;
    cellCSS: string;
    isDisplay = true;
    fieldPrefix = '';
    subReportId: Number;
    autoSearchKey: string;
    defDateDurationInDays: number = 0;
    //~~~~~~~~~~~~ Tushar 16-10-2023 GID 477
    tableFieldName: string;
    pageId: number = 0;
    //~~~~~~~~~~~~ GID 477
}